<template>
  <div class="main-content" >
    <h1>User Guide</h1>
    <div class="container-fluid content-row">
    <div class="row">
      <b-col cols="4" >
      <b-card class="cust">
        <vue-tree-navigation @ :items="items" :defaultOpenLevel="1" />
      </b-card>
      </b-col>
      <b-col cols="8">
      <b-card class="h-100" >
        <h1  style="color: #88cabe; font-weight: bolder; font-size: xx-large">{{ userguidepage ? userguidepage['title_'+$i18n.locale.toUpperCase()]: '' }} 
              
              <b-button type=”button”
                        v-if="$store.state.user.currentUser.organizationLabel === 'mintt'"
                        variant="light"                  
                        v-clipboard:copy="link"
                        v-clipboard:success="onCopy"     
                        v-clipboard:error="onError"             
                        ><font-awesome-icon style="margin-right: 1rem;" icon="link"/>{{$t('Copy direct link')}}</b-button> </h1>
                        <span>{{$t('last updated at ') + userguidepage['updated_at_'+$i18n.locale.toUpperCase()]}}</span>
        <div class="ml-auto p-2"></div>
        <br/>
        <p class="guidecontent" v-html="userguidepage ? userguidepage['content_'+$i18n.locale.toUpperCase()] : ''"></p>
        <ISAKioskHelp v-if="userguidepage && userguidepage.id == '612ec7ca94c36101415a010a'" :skipHeader="true">          
        </ISAKioskHelp>
        </b-card>
      </b-col>
    </div>
    </div>
    
  </div>
</template>



<script>

import { AuthService } from "@/services/auth.service";
import { SPService } from "@/services/sp.service";
import { API_URL } from '@/.env'
import axios from 'axios';
import marked from 'marked';
import ISAKioskHelp from '../../../components/ISA/help/ISAKioskHelp';


// opts are optional
const opts = {
  xhtml: false,
  fqImages: { route: '/uploads', url: API_URL.url + '/doc/res' }, // use custom image endpoint url when link starts with route
  fqLinks: 'https://www.example.com',                                // generate fully qualified links if fqImages is not set
  relPath: true
}

function markedImages(opts) {

  opts = opts || {};
  var imgRoute = opts.fqImages && (opts.fqImages.route || '/images/');
  var imgPrefix = opts.fqImages && opts.fqImages.url;
  var linkPrefix = opts.fqLinks || opts.relPath;
  var xhtml = opts.xhtml;

  return { renderer: {image: renderImage, link: renderLink} };

  function renderImage(href, title, text) {

    var out, iframe;
    
    if (imgPrefix && href.startsWith(imgRoute)) { href = imgPrefix + href; }
    else if (linkPrefix && /^\/([^/]|$)/.test(href)) { href = linkPrefix + href; }

    if (href && (m = href.match(/vimeo\/(\d+)/i))) {
      iframe = true;
      out = '<iframe src="//player.vimeo.com/video/' + m[1] + '"' +
              ' frameborder="0" webkitAllowFullScreen mozallowfullscreen allowFullScreen';
    }
    else {
      out = '<img src="' + href + '" alt="' + text + '"';
    }

    var a = (title && title.split(/\s+/)) || [];
    var b = [];
    var classNames = [];
    var m;
    a.forEach(function(w) {
      if ((m = w.match(/^(\d+)x(\d+)$/))) return (out += ' width="' + m[1] + '" height="' + m[2] + '"');
      if ((m = w.match(/^(\w+)=([\w-]+)$/))) {
        if (m[1] === 'class') return classNames.push(m[2]);
        return (out += ' ' + m[1] + '="' + m[2] + '"');
      }
      if ((m = w.match(/^\.([\w-]+)$/))) return classNames.push(m[1]);
      if (w) return b.push(w);
    });

    if (classNames.length) {
      out += ' class="' + classNames.join(' ') + '"';
    }

    title = b.join(' ');

    if (title) {
      out += ' title="' + title + '"';
    }

    out += iframe ? '></iframe>' :
           xhtml ? '/>' :
           '>';

    return out;
  }
  function renderLink(href, title, text) {
      if(text.indexOf('@') > 0) {
        return `<a href="mailto:${text}" >${text}</a>`  
      } else if (href.indexOf('http') === 0) {
        return `<a href="${href}" target="_blank">${text}</a>`
      }
      return `<a href="#" onclick="console.log('hey'); if(window.MintTNav) window.MintTNav('${href}')">${text}</a>`
  }
};



marked.use(markedImages(opts));

function processPage(val) {
  
  for(let c in val) {
            if(c.startsWith('content_')) {
              let text = val[c]
              text = text.replace('Customer Success Manager','[Customer Success Manager](/app/userguide?hi=612ecb1894c36101415a010e)');
              text = text.replace('MintT','*[MintT](#)*');
              text = text.replace('MyIsa','*[MyISA](#)*');
              text = text.replace('MyISA','*[MyISA](#)*');

              val[c] = marked(text);
            }
          }  
  
   if(val.children)
   for(let child of val.children)
      if(child)
        processPage(child);
}



function recursiveHelpItemSearch(items, id) {
  if(items) {
    for(let item of items) {
      if(item.id === id) return item;
      let childSearch = item.children && item.children.length > 0 ? recursiveHelpItemSearch(item.children, id) : null;
      if(childSearch) return childSearch;
    }
  }
}
 function navigateTo(to) {
      window.alert('got it ' + to);
    }

export default {
  name: "UserGuide",
  data() {
          return {
            items: [{"title_EN":"Loading...","title_FR":"Chargement en cours...", "title_NL":"Loading..."}],
            userguidepage: {},
            
          }
        },

  components: {
    ISAKioskHelp
  },
  computed: {
    link() {
      const link = `${window.location.origin}/app/userguide?hi=${this.userguidepage ? this.userguidepage.id : null}`
      return link
    },
  },
  watch: {
    '$store.state.user.userguidePage': async function(val,oldVal) {      
      /*for(let c in val) {
        if(c.startsWith('content_')) {
          let text = val[c];
          text = text.replace('[Customer Success Manager](/app/userguide?hi=612ecb1894c36101415a010e)', 'Customer Success Manager');
          text = text.replace('Customer Success Manager','[Customer Success Manager](/app/userguide?hi=612ecb1894c36101415a010e)');
          val[c] = marked(text);
        }
      }*/
      this.userguidepage = val;   
      window.scrollTo(0, 0);

       
       window.history.pushState({ mintt: 'navigate'}, '', window.location.origin  + `/app/userguide?hi=${this.userguidepage.id}`)
        const data = {
         fingerprint: this.$store.state.user.fingerprint,
          action: 'userguide',
          path: `/app/userguide?hi=${this.userguidepage.id}`,
          data: this.userguidepage.title_EN,
        }
        try {
              await SPService.wbevent(API_URL.url, this.$store.state.user.currentUser.jwt, data);
        } catch (err) {

        }                
    },
  },
  mounted() {
    const self = this;
    window.MintTNav = (href) => {
            if(href === "#") return;
            console.log('going to' + href);
            let hi = href.replace('/app/userguide?hi=','');
            let item = recursiveHelpItemSearch(self.items, hi);
            if(!item)
              item = self.items[0];
            self.$store.commit('user/SET_USERGUIDE_PAGE', item); 
            self.userguidepage = item                
        }

    axios.get(`${API_URL.url}/doc/items`, {
          headers: {
            Authorization: 'Bearer ' +  self.$store.state.user.currentUser.jwt
          }
        }).then((result) => {

           // this.items = result.data.sort((a,b) => (a.order||100) - (b.order||100));

            const rawitems = result.data.sort((a,b) => (a.order||100) - (b.order||100));
            this.items = [];
            for(let val of rawitems) {
              processPage(val);

              this.items.push(val)        ;
            }

        if(self.$attrs && self.$attrs.hi) {
            
            let item = recursiveHelpItemSearch(self.items, self.$attrs.hi);
            if(!item)
              item = self.items[0];
            self.$store.commit('user/SET_USERGUIDE_PAGE', item); 
            self.userguidepage = item             
        } else {
            self.$store.commit('user/SET_USERGUIDE_PAGE', self.items[0]);
            self.userguidepage = self.items[0]                
        }

        
            
        }).catch((reason) => {

        })
  },
  created() {
    const self = this;
    window.onpopstate = function(event) {      
      
      if(event.state && event.state.mintt && document.location.href.indexOf('app/userguide?hi=') >= 0) {
        let i = document.location.href.indexOf('hi=');
        let hi = document.location.href.substring(i+3);
        
        if(self.userguidepage && self.userguidepage.id === hi) // back from a link with #
        {
          history.back();
          return;
        }

        let item = recursiveHelpItemSearch(self.items, hi);
        if(!item)
          item = self.items[0];
        self.userguidepage = item
        self.$store.commit('user/SET_USERGUIDE_PAGE', item); 
        
      }
      console.log("adresse: " + document.location + ", état: " + JSON.stringify(event.state));
    };
  },
  methods: {
   marked,
    onCopy(e) {
      this.$bvToast.toast(this.$t('The link to this help page has been copied, you can now share it.\nOnly authenticated people will be able to access it.'), { title: this.$t('Help Page'), solid: true,  variant: 'success',  duration: 3000 });
    },
    onError(e) {
      this.$bvToast.toast(this.$t('Your browser does not support this feature.'), { title: this.$t('Help Page'), solid: true,  variant: 'warning',  duration: 3000 });
    },  
   
  }
};
</script>

<style lang="scss" scoped>
.my-flex-card {
    height: calc(100% - 15px);
    margin-bottom: 15px;
}

.TreeNavigation {
    display: inline-block;
    padding: 0;
    margin: 0;
}


.NavigationToggle__icon {
    display: inline-block;
    padding: 3px;
    border: solid #000;
        border-top-width: medium;
        border-right-width: medium;
        border-bottom-width: medium;
        border-left-width: medium;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
}

.NavigationToggle {
    position: relative;
    top: -3px;
    padding: 5px 5px 5px 3px;
    cursor: pointer;
}

.guidecontent {
  font-size: x-large;
}

.card-body .cust{
    flex-wrap: nowrap;
    white-space: nowrap;
    max-height: 72vh;
    overflow: auto;
    font-size: x-small;
}



</style>

<style >
.NavigationItem span {
    cursor: pointer;
    font-size: medium !important;
}
</style>
